import { ACRO_FOR_DANCERS, ADULT_GYM } from '@/assets/classTypes/acro'
import { ADULT_CIRCUS, JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS } from '@/assets/classTypes/circus'
import { JR_BOYS_GYM, JR_GIRLS_GYM, JR_KINDERGYM, PARENT_AND_TOT, SR_GIRLS_GYM, SR_KINDERGYM, TEEN_GYM, TOTS_KINDERGYM } from '@/assets/classTypes/gymnastics'
import { JR_NINJA, SR_NINJA } from '@/assets/classTypes/ninja'

import ACRO from '@/assets/disciplines/acro'
import CIRCUS from '@/assets/disciplines/circus'
import GYMNASTICS from '@/assets/disciplines/gymnastics'
import NINJA from '@/assets/disciplines/ninja'

export default {
  // Menu
  title: 'Fall Session',
  description: 'Runs September–December',

  // Route parameter & Jackrabbit identifier
  name: 'fall',
  id: 'Fall 2024', // Session identifier in Jackrabbit, e.g. Fall 2024

  // Disciplines & class types
  disciplines: [
    {
      ...GYMNASTICS,
      classTypes: [PARENT_AND_TOT, TOTS_KINDERGYM, JR_KINDERGYM, SR_KINDERGYM, JR_BOYS_GYM, JR_GIRLS_GYM, SR_GIRLS_GYM, TEEN_GYM],
    },
    { ...CIRCUS, classTypes: [JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS, ADULT_CIRCUS] },
    { ...ACRO, classTypes: [ACRO_FOR_DANCERS, ADULT_GYM] },
    { ...NINJA, classTypes: [JR_NINJA, SR_NINJA] },
  ],

  // Registration date announcement & session details
  announcement: 'Registration opens July 22nd at 9pm',
  details: `This session runs on the following dates:
  
  • Saturday classes: September 7th–December 14th (14 weeks)
  • Sunday classes: September 8th–December 15th (14 weeks)
  • Monday classes: September 9th–December 16th (14 weeks)
  • Tuesday classes: September 10th–December 17th (15 weeks)
  • Wednesday classes: September 11th–December 18th (15 weeks)
  • Thursday classes: September 12th–December 19th (14 weeks)

  Classes will not run:
  • October 11th–14th due to Thanksgiving
  • October 31st due to Halloween
  
  Each participant must have valid Gymnastics Ontario insurance. A non-refundable insurance fee of $50 + HST applies to each as-of-yet uninsured participant, and is valid until next July.`,
}

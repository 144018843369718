<template>
  <v-img :src="image" :position="position" :gradient="`20deg, rgba(0,0,0,${darkness}), rgba(0,0,0,0.1)`" class="elevation-12" :aspect-ratio="10">
    <v-container class="white--text pb-12" :style="{ 'padding-top': $vuetify.breakpoint.xs ? '60px' : '300px' }">
      <p v-if="session" class="text-h3 font-fancy">{{ session }}</p>
      <p class="text-h2 secondary--text font-fancy">{{ title }}</p>
      <p class="text-h4 mt-12">{{ subtitle }}</p>
    </v-container>
  </v-img>
</template>

<script>
export default {
  props: {
    session: String,
    title: String,
    subtitle: String,
    image: String,
    position: String,
    darkness: {
      type: Number,
      default: 0.9,
    },
  },
}
</script>

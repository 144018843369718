<template>
  <main v-if="session && discipline">
    <Hero :title="discipline.hero.title" :session="session.title" :subtitle="discipline.hero.subtitle" :image="discipline.hero.image" :position="discipline.hero.position" />
    <Welcome :title="discipline.welcome.title" :text="discipline.welcome.text" :button-text="discipline.welcome.buttonText" identifier="#available-classes" />

    <div id="session-info" class="white--text">
      <v-container>
        <p class="text-h4 font-fancy" style="white-space: pre-line">{{ session.announcement }}</p>
        <p class="mt-6" style="white-space: pre-line">{{ session.details }}</p>
      </v-container>
    </div>

    <div id="available-classes">
      <v-container>
        <ClassList :session-id="session.id" :class-types="discipline.classTypes" />
      </v-container>
    </div>

    <Learning />
    <MailingListSignup />
    <Awards />
    <Map />
    <Footer />
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Welcome from '@/components/Welcome'
import ClassList from '@/components/ClassList'
import Learning from '@/components/Learning'
import MailingListSignup from '@/components/MailingListSignup'
import Awards from '@/components/Awards'
import Map from '@/components/Map'
import Footer from '@/components/Footer'

import SESSIONS from '@/assets/sessions'

export default {
  components: {
    Hero,
    Welcome,
    ClassList,
    Learning,
    MailingListSignup,
    Awards,
    Map,
    Footer,
  },
  data() {
    return {
      session: null,
      discipline: null,
    }
  },
  mounted() {
    this.setData(this.$route.params)
  },
  methods: {
    setData(params) {
      // Select new session and discipline based on route params
      const { session, discipline } = params
      this.session = SESSIONS.find(s => s.name === session)
      this.discipline = this.session.disciplines.find(d => d.name === discipline)
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.setData(to.params)
    next()
  },
}
</script>

<style scoped>
#session-info {
  background-image: url('../assets/black-tile.jpg');
  background-repeat: repeat;
  padding-top: 100px;
  padding-bottom: 100px;
}
#available-classes {
  background-image: url('../assets/orange-background.jpg');
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>

import { ACRO_FOR_DANCERS, ADULT_GYM } from '@/assets/classTypes/acro'
import { ADULT_CIRCUS, JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS } from '@/assets/classTypes/circus'
import { JR_BOYS_GYM, JR_GIRLS_GYM, JR_KINDERGYM, PARENT_AND_TOT, SR_GIRLS_GYM, SR_KINDERGYM, TEEN_GYM, TOTS_KINDERGYM } from '@/assets/classTypes/gymnastics'
import { JR_NINJA, SR_NINJA } from '@/assets/classTypes/ninja'

import ACRO from '@/assets/disciplines/acro'
import CIRCUS from '@/assets/disciplines/circus'
import GYMNASTICS from '@/assets/disciplines/gymnastics'
import NINJA from '@/assets/disciplines/ninja'

export default {
  // Menu
  title: 'Winter Session',
  description: 'Runs January–March',

  // Route parameter & Jackrabbit identifier
  name: 'winter',
  id: 'Winter 2025', // Session identifier in Jackrabbit, e.g. Winter 2023

  // Disciplines & classes
  disciplines: [
    {
      ...GYMNASTICS,
      classTypes: [PARENT_AND_TOT, TOTS_KINDERGYM, JR_KINDERGYM, SR_KINDERGYM, JR_BOYS_GYM, JR_GIRLS_GYM, SR_GIRLS_GYM, TEEN_GYM],
    },
    { ...CIRCUS, classTypes: [JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS, ADULT_CIRCUS] },
    {
      ...ACRO,
      classTypes: [ACRO_FOR_DANCERS, ADULT_GYM],
    },
    { ...NINJA, classTypes: [JR_NINJA, SR_NINJA] },
  ],

  // Registration date announcement & session details
  announcement: 'Registration open now',
  // TODO !! Increase GO fee beginning Winter 2026
  details: `This session runs on the following dates:

  • Saturday classes: January 4th–March 22nd (9 weeks)
  • Sunday classes: January 5th–March 23rd (9 weeks)
  • Monday classes: January 6th–March 24th (9 weeks)
  • Tuesday classes: January 7th–March 25th (12 weeks)
  • Wednesday classes: January 8th–March 26th (11 weeks)
  • Thursday classes: January 9th–March 27th (10 weeks)

  Classes will not run:
  • January 23rd–27th (Competition)
  • February 14th–17th (Family Day Weekend)
  • March 5th–10th (Competition)

  Each participant must have valid Gymnastics Ontario insurance. A non-refundable insurance fee of $46 + HST applies to each as-of-yet uninsured participant, and is valid until next July.`,
}

export const ACRO_FOR_DANCERS = {
  title: 'Acro for Dancers',
  gender: 'Girls & Boys',
  ages: 'Ages 8–17',
  description:
    "This 1-hour focus class is for recreational and competitive dancers looking to gain an extra acro edge to their numbers. Take advantage of our gymnastics knowledge, teaching progressions, equipment and facility! Open to boys and girls ages 8–17. Class will be tailored to each individual's goals. Dancers will have access to the Floor area as well as Tumble Track.",
  cat1: 'Acro for Dancers',
}

export const ADULT_GYM = {
  title: 'Adult Gym',
  gender: 'Co-Ed',
  ages: 'Ages 18+',
  description: 'This semi-structured class is for adults looking to develop or improve their strength, flexibility, and acro and gymnastics skills. The class runs once per week for 1 hour 25 minutes.',
  cat1: 'Acro for Adults',
}

<template>
  <div class="pb-12 white--text">
    <PartyList :parties="parties" />
  </div>
</template>

<script>
import * as adapters from '@/adapters'

import PartyList from './PartyList'

export default {
  components: {
    PartyList,
  },
  props: {
    sessionId: String,
    cat1: String,
  },
  data() {
    return {
      parties: [],
    }
  },
  async mounted() {
    this.setData()
  },
  methods: {
    async setData() {
      // Fetch parties from API
      const url = '/api/parties/'
      const payload = { session: this.sessionId, cat1: this.cat1 }
      const response = await this.$http.post(url, payload)
      // Map parties into frontend representation
      const parties = adapters.parties.fromApi(response.data)
      this.parties = parties
    },
  },
}
</script>

<template>
  <v-app>
    <!-- Mobile menu -->
    <v-navigation-drawer v-model="drawer" app stateless temporary>
      <v-list nav dense>
        <v-list-item>
          <v-btn icon @click.stop="drawer = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
        <template v-for="item in mobileMenu">
          <div v-if="item.items" :key="item.title">
            <template v-for="subitem in item.items">
              <NavDrawerItemNested v-if="!item.disabled" :key="subitem.title" v-bind="subitem" :category="item.title" />
            </template>
          </div>
          <template v-else>
            <NavDrawerItem v-if="!item.disabled" :key="item.title" v-bind="item" />
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- App bar -->
    <v-app-bar color="primary" dark>
      <v-app-bar-nav-icon v-if="mobile" @click.stop="drawer = !drawer" data-testid="mobile-menu_open-button" />
      <router-link v-else :to="{ name: 'home' }">
        <v-img alt="Whitby Gym &amp; Circus logo" contain :src="require('@/assets/logo-icon-white.png')" :height="25" :width="25" class="mr-8" />
      </router-link>
      <v-toolbar-title>
        <router-link :to="{ name: 'home' }" class="white--text" style="text-decoration: none"> Whitby Gym &amp; Circus </router-link>
      </v-toolbar-title>
      <v-spacer />

      <!-- Desktop menu -->
      <div v-show="!mobile">
        <AppBarMenuDynamic title="Classes" badge="New" url="/api/menus/classes/" />
        <AppBarMenuDynamic title="Camps" badge="New" url="/api/menus/camps/" />
        <template v-for="item in desktopMenu">
          <AppBarMenu v-if="item.items" :key="item.title" v-bind="item" />
          <AppBarItem v-else :key="item.title" v-bind="item" />
        </template>
      </div>
    </v-app-bar>

    <!-- Content -->
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import AppBarMenu from '@/components/AppBarMenu'
import AppBarMenuDynamic from '@/components/AppBarMenuDynamic'
import AppBarItem from '@/components/AppBarItem'
import NavDrawerItem from '@/components/NavDrawerItem'
import NavDrawerItemNested from '@/components/NavDrawerItemNested'

const INITIAL_MENU = [
  {
    title: 'Parties',
    badge: null,
    href: '/parties', // TODO replace route strings with route path constants
  },
  {
    title: 'Competitive',
    badge: null,
    href: '/competitive', // TODO replace route strings with route path constants
  },
  {
    title: 'Parent Portal',
    badge: null,
    href: 'https://app.jackrabbitclass.com/jr3.0/ParentPortal/Login?orgId=524322',
  },
]

export default {
  components: {
    AppBarMenu,
    AppBarMenuDynamic,
    AppBarItem,
    NavDrawerItem,
    NavDrawerItemNested,
  },
  data() {
    return {
      drawer: false,
      desktopMenu: INITIAL_MENU,
      mobileMenu: INITIAL_MENU,
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
  },
  watch: {
    drawer(isOpening) {
      if (isOpening) this.fetchMenus()
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route.path)
  },
  methods: {
    async fetchMenus() {
      const timestamp = Date.now()
      const cacheBuster = `?t=${timestamp}`
      const classesUrl = `/api/menus/classes/${cacheBuster}`
      const classes = await this.$http.get(classesUrl)
      const campsUrl = `/api/menus/camps/${cacheBuster}`
      const camps = await this.$http.get(campsUrl)
      this.mobileMenu = [...classes.data, ...camps.data, ...INITIAL_MENU]
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/styles.scss'; // Necessary for fancy font throughout app
</style>

<template>
  <v-sheet class="py-12" dark>
    <v-container
      class="pa-6"
      style="border-radius: 6px"
      :style="{
        border: `2px solid ${this.$vuetify.theme.themes.light.secondary.base}`,
      }"
    >
      <p class="text-h4 font-fancy">Join the mailing list</p>
      <div class="text-h6 mt-6">Be the first to know</div>
      <div class="mt-2">
        <ul>
          <li>When class schedules are posted</li>
          <li>When registration begins</li>
          <li>When policies change and require your action</li>
        </ul>
      </div>

      <!-- Signup form -->
      <v-form ref="form" v-model="valid" class="mt-10">
        <v-text-field v-model.trim="name" label="Your name" color="secondary" :rules="nameRules" outlined data-testid="mailing-list_name-input" @keyup.enter="submit" />
        <v-text-field v-model.trim="email" label="Your email" color="secondary" :rules="emailRules" outlined data-testid="mailing-list_email-input" @keyup.enter="submit" />
      </v-form>
      <v-btn class="secondary" x-large block :loading="loading" data-testid="mailing-list_submit-button" @click="submit"> Join mailing list </v-btn>

      <!-- Snackbar -->
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbarDismiss"> Dismiss </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      email: '',
      name: '',
      loading: false,
      snackbar: false,
      snackbarColor: null,
      snackbarMessage: '',
    }
  },
  computed: {
    emailRules() {
      return [
        v => !!v || 'Email is required',
        v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Must be a valid email'
        },
      ]
    },
    nameRules() {
      return [v => !!v || 'Name is required', v => v.length <= 100 || 'Too long']
    },
  },
  methods: {
    async submit() {
      if (!this.valid) return this.$refs.form.validate()
      this.loading = true
      let response
      const url = '/api/mailing-list/'
      const payload = { customer_email: this.email, customer_name: this.name }
      try {
        response = await this.$http.post(url, payload)
      } catch (error) {
        this.snackbarColor = 'error'
        this.snackbarMessage = error.response.data
        this.snackbar = true
        this.loading = false
        return
      }
      this.snackbarColor = 'success'
      this.snackbarMessage = response.data
      this.snackbar = true
      this.loading = false
    },
    snackbarDismiss() {
      this.snackbar = false
    },
  },
}
</script>

<style scoped>
.v-sheet {
  background-image: url('../assets/black-tile.jpg');
  background-repeat: repeat;
}
</style>

<template>
  <main>
    <v-carousel>
      <!-- Default -->
      <v-carousel-item :src="require('@/assets/hero-home.jpg')" :gradient="`rgba(0,0,0,0.4), rgba(0,0,0,0.4)`">
        <v-row class="fill-height" justify="center" align="center">
          <div>
            <p class="text-center display-3 white--text">Whitby Gym &amp; Circus</p>
            <p class="text-center display-1 secondary--text font-fancy">Gymnastics, Circus, Acro, Tricks &amp; Tumbling Classes</p>
          </div>
        </v-row>
      </v-carousel-item>
    </v-carousel>

    <Welcome
      title="Welcome to The Club"
      :text="`At Whitby Gym & Circus, we welcome all families to a fun, nurturing, and safe environment where children are inspired to learn gymnastics. We offer a wide variety of gymnastics-based programs for children of all ages, including recreational and competitive gymnastics, circus, acro, and tricks & tumbling classes!`"
      button-text="View our programs"
      identifier="#our-programs"
    />

    <MailingListSignup />
    <Awards />
    <Map />
    <Footer />
  </main>
</template>

<script>
import Welcome from '@/components/Welcome'
import Awards from '@/components/Awards'
import Map from '@/components/Map'
import Footer from '@/components/Footer'
import MailingListSignup from '@/components/MailingListSignup'

export default {
  components: {
    Welcome,
    Awards,
    Map,
    Footer,
    MailingListSignup,
  },
  data() {
    return {
      streams: [
        {
          title: 'Recreational',
          programs: [
            {
              title: 'Gymnastics',
              image: require('@/assets/hero-gymnastics.jpg'),
              route: { name: 'session', params: { session: 'fall', discipline: 'gymnastics' } },
            },
            {
              title: 'Circus',
              image: require('@/assets/hero-circus.jpg'),
              route: { name: 'session', params: { session: 'fall', discipline: 'circus' } },
            },
            {
              title: 'Acro',
              image: require('@/assets/hero-acro.jpg'),
              route: { name: 'session', params: { session: 'fall', discipline: 'acro' } },
            },
            {
              title: 'Tricks & Tumbling',
              image: require('@/assets/hero-ninja.jpg'),
              route: { name: 'session', params: { session: 'fall', discipline: 'tricks-and-tumbling' } },
            },
          ],
        },
        {
          title: 'Competitive',
          programs: [
            {
              title: 'Girls Artistic',
              image: require('@/assets/hero-competitive.jpg'),
              route: { name: 'competitive' },
            },
          ],
        },
      ],
    }
  },
}
</script>

<style>
#our-programs {
  background-color: var(--v-primary-base);
  padding-top: 150px;
  padding-bottom: 150px;
}
</style>

import { ACRO_FOR_DANCERS, ADULT_GYM } from '@/assets/classTypes/acro' // eslint-disable-line no-unused-vars
import { ADULT_CIRCUS, JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS } from '@/assets/classTypes/circus' // eslint-disable-line no-unused-vars
import { JR_BOYS_GYM, JR_GIRLS_GYM, JR_KINDERGYM, PARENT_AND_TOT, SR_GIRLS_GYM, SR_KINDERGYM, TEEN_GYM, TOTS_KINDERGYM } from '@/assets/classTypes/gymnastics'
import { JR_NINJA, SR_NINJA } from '@/assets/classTypes/ninja'

import ACRO from '@/assets/disciplines/acro'
import CIRCUS from '@/assets/disciplines/circus'
import GYMNASTICS from '@/assets/disciplines/gymnastics'
import NINJA from '@/assets/disciplines/ninja'

export default {
  // Menu
  title: 'Summer Session',
  description: 'Runs July–August',

  // Route parameter & Jackrabbit identifier
  name: 'summer',
  id: 'Summer 2024', // Session identifier in Jackrabbit, e.g. Summer 2023

  // Disciplines & classes
  disciplines: [
    {
      ...GYMNASTICS,
      classTypes: [PARENT_AND_TOT, TOTS_KINDERGYM, JR_KINDERGYM, SR_KINDERGYM, JR_BOYS_GYM, JR_GIRLS_GYM, SR_GIRLS_GYM, TEEN_GYM],
    },
    {
      ...CIRCUS,
      classTypes: [JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS, ADULT_CIRCUS],
    },
    { ...NINJA, classTypes: [JR_NINJA, SR_NINJA] },
    {
      ...ACRO,
      classTypes: [ACRO_FOR_DANCERS, ADULT_GYM],
    },
  ],

  // Registration date announcement & session details
  announcement: 'Registration opens June 1st at 9pm',
  details: `This session runs on the following dates:

  • Tuesday classes: July 9th–August 27th
  • Wednesday classes: July 10th–August 28th
  • Thursday classes: July 11th–August 29th
  
  Each participant must have valid Gymnastics Ontario insurance. A non-refundable insurance fee of $50 + HST applies to each as-of-yet uninsured participant, and is valid until next July.`,
}

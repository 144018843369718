import { ACRO_FOR_DANCERS, ADULT_GYM } from '@/assets/classTypes/acro' // eslint-disable-line no-unused-vars
import { ADULT_CIRCUS, JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS } from '@/assets/classTypes/circus'
import { JR_BOYS_GYM, JR_GIRLS_GYM, JR_KINDERGYM, PARENT_AND_TOT, SR_GIRLS_GYM, SR_KINDERGYM, TEEN_GYM, TOTS_KINDERGYM } from '@/assets/classTypes/gymnastics'
import { JR_NINJA, SR_NINJA } from '@/assets/classTypes/ninja'

import ACRO from '@/assets/disciplines/acro'
import CIRCUS from '@/assets/disciplines/circus'
import GYMNASTICS from '@/assets/disciplines/gymnastics'
import NINJA from '@/assets/disciplines/ninja'

export default {
  // Menu
  title: 'Spring Session',
  description: 'Runs April–June',

  // Route parameter & Jackrabbit identifier
  name: 'spring',
  id: 'Spring 2025', // Session identifier in Jackrabbit, e.g. Spring 2023

  // Disciplines & classes
  disciplines: [
    {
      ...GYMNASTICS,
      classTypes: [PARENT_AND_TOT, TOTS_KINDERGYM, JR_KINDERGYM, SR_KINDERGYM, JR_BOYS_GYM, JR_GIRLS_GYM, SR_GIRLS_GYM, TEEN_GYM],
    },
    { ...CIRCUS, classTypes: [JR_CIRCUS, SR_CIRCUS, TEEN_CIRCUS, ADULT_CIRCUS] },
    {
      ...ACRO,
      classTypes: [
        ACRO_FOR_DANCERS,
        // ADULT_GYM
      ],
    },
    { ...NINJA, classTypes: [JR_NINJA, SR_NINJA] },
  ],

  // Registration date announcement & session details
  announcement: 'Registration open now',
  // TODO !! Increase GO fee beginning Spring 2026
  details: `This session runs on the following dates:

  • Saturday classes: March 29th–June 21st (10 weeks)
  • Sunday classes: March 30th–June 22nd (10 weeks)
  • Monday classes: March 31st–June 23rd (10 weeks)
  • Tuesday classes: April 1st–June 24th (13 weeks)
  • Wednesday classes: April 2nd–June 18th (11 weeks)
  • Thursday classes: April 3rd–June 19th (11 weeks)
  
  Classes will not run:
  • Friday April 18th–Monday April 21st (Easter Weekend)
  • Wednesday May 7th–Monday May 12th (Competition)
  • Friday May 16th–Monday May 19th (Victoria Day Weekend)

  Each participant must have valid Gymnastics Ontario insurance. A non-refundable insurance fee of $46 + HST applies to each as-of-yet uninsured participant, and is valid until July.`,
}

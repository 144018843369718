<template>
  <v-form ref="form" v-model="valid">
    <v-text-field v-model="name" label="Name" :rules="nameRules" filled background-color="white" required validate-on-blur :disabled="status == 'loading'" data-testid="contact-form_name-input"></v-text-field>
    <v-text-field v-model="email" label="Email" :rules="emailRules" filled background-color="white" required :disabled="status == 'loading'" data-testid="contact-form_email-input"></v-text-field>
    <v-text-field v-model="message" label="Message" :rules="messageRules" filled background-color="white" :height="150" required validate-on-blur :disabled="status == 'loading'" data-testid="contact-form_message-input"></v-text-field>
    <v-row justify="center" align="center" style="min-height: 45px">
      <v-icon v-if="status == 'failure'" color="error" :size="40" class="animated shake fast">mdi-close</v-icon>
      <v-icon v-else-if="status == 'success'" color="success" :size="40">mdi-check</v-icon>
      <span v-else-if="status == 'talk-soon'" class="white--text headline">Talk Soon</span>
      <v-btn v-else :loading="status == 'loading'" :disabled="status == 'loading'" large color="secondary" rounded @click="submit" data-testid="contact-form_submit-button">Send Message </v-btn>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      name: '',
      email: '',
      message: '',
      nameRules: [v => !!v || 'Name is required', v => (v && v.length > 1) || 'Too short', v => (v && v.length <= 50) || 'Too long'],
      emailRules: [v => !!v || 'Email is required', v => /.+@.+\..+/.test(v) || 'Not a valid email'],
      messageRules: [v => !!v || 'Message is required', v => (v && v.length > 10) || 'Too short', v => (v && v.length <= 1000) || 'Too long'],
      status: 'ready',
    }
  },
  methods: {
    async submit() {
      this.$refs.form.validate()
      if (this.valid) {
        this.status = 'loading'
        const url = '/api/contact-form/'
        await this.$http.post(url, {
          customer_name: this.name,
          customer_email: this.email,
          customer_message: this.message,
        })
        this.status = 'success'
        setTimeout(() => {
          this.status = 'talk-soon'
          setTimeout(() => {
            this.status = 'ready'
            this.$refs.form.reset()
          }, 2000)
        }, 2000)
      } else {
        this.status = 'failure'
        setTimeout(() => (this.status = 'ready'), 2000)
      }
    },
  },
}
</script>

<template>
  <section class="pt-12 grey darken-4">
    <v-container>
      <v-row>
        <v-col class="mt-12 white--text" cols="12" sm="6">
          <p class="text-h4 font-fancy">Contact Us</p>
          <FooterButton icon="mdi-map-marker" text="330 Taunton Rd. E. Unit C5, Whitby, ON L1R 0H4" link="https://goo.gl/maps/48ZJjo7S76d2HdPo6" />
          <FooterButton icon="mdi-email" text="info@whitbygymandcircus.com" link="mailto:info@whitbygymandcircus.com" />
          <FooterButton icon="mdi-instagram" text="@whitbygymcircus" link="https://www.instagram.com/whitbygymcircus/" />
          <FooterButton icon="mdi-facebook" text="@whitbygymcircus" link="https://www.facebook.com/whitbygymcircus/" />
        </v-col>

        <v-col class="mt-12" cols="12" sm="6">
          <v-img :src="require('@/assets/gym-ont.png')" class="mx-auto" style="width: 60%"></v-img>
          <p class="white--text text-center caption">Proud member of Gymnastics Ontario</p>
        </v-col>
      </v-row>
    </v-container>

    <!-- Copyright & credit -->
    <div class="pt-12 pb-3 text-center">
      <p class="white--text">&#169; {{ currentYear }} Whitby Gymnastics &amp; Circus Centre</p>
      <p>
        <a class="white--text text-decoration-none" href="https://readysetgo.co" target="_blank">
          Website by
          <v-icon class="mb-1" color="white">mdi-play-circle</v-icon>
          Ready Set Go
        </a>
      </p>
    </div>
  </section>
</template>

<script>
import moment from 'moment'

import FooterButton from '@/components/FooterButton'

export default {
  components: {
    FooterButton,
  },
  computed: {
    currentYear() {
      return moment().format('YYYY')
    },
  },
}
</script>

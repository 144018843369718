<template>
  <v-dialog v-model="dialog" :fullscreen="mobile" :hide-overlay="mobile" :transition="mobile ? 'dialog-bottom-transition' : null" :max-width="mobile ? null : 800">
    <!-- Time listing -->
    <template v-slot:activator="{ attrs, on }">
      <div v-bind="attrs" v-on="on">
        <ClassTime :formatted-start-time="formattedStartTime" :is-class-full="isClassFull" :tooltip-text="buttonText" />
      </div>
    </template>

    <!-- Details modal -->
    <v-card>
      <v-toolbar dark>
        <!-- Close button -->
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- Class title -->
        <v-toolbar-title v-if="!mobile">{{ title }}</v-toolbar-title>

        <!-- Register button -->
        <v-spacer />
        <v-toolbar-items>
          <v-btn :disabled="!isRegistrationLinkIncluded" :href="registrationUrl" :text="isClassFull" color="secondary" @click="dialog = false">
            {{ buttonText }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- Class details -->
      <v-list three-line>
        <v-list-item v-if="mobile">
          <v-list-item-content>
            <v-list-item-title>Class</v-list-item-title>
            <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Day &amp; time</v-list-item-title>
            <v-list-item-subtitle>{{ dayAndTime }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Gender</v-list-item-title>
            <v-list-item-subtitle>{{ gender }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Age</v-list-item-title>
            <v-list-item-subtitle>{{ ages }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>First class</v-list-item-title>
            <v-list-item-subtitle>{{ formattedStartDate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Last class</v-list-item-title>
            <v-list-item-subtitle>{{ formattedEndDate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Fee</v-list-item-title>
            <v-list-item-subtitle>{{ formattedFee }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="mb-8">
          <v-list-item-content>
            <v-list-item-title>Details</v-list-item-title>
            <v-list-item-subtitle class="full-length">{{ description }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

import ClassTime from './ClassTime'

export default {
  components: {
    ClassTime,
  },
  props: {
    ages: String,
    description: String,
    endDate: Object,
    fee: Number,
    gender: String,
    registrationDate: Object,
    registrationUrl: String,
    spotsLeft: Number,
    startDate: Object,
    startTime: Object,
    title: String,
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    day() {
      return this.startDate.format('dddd')
    },
    formattedSpotsLeft() {
      const isSingular = this.spotsLeft === 1
      let spots = isSingular ? 'spot' : 'spots'
      return `${this.spotsLeft} ${spots} left`
    },
    isClassFull() {
      const isClassFull = this.spotsLeft === 0
      return isClassFull
    },
    isRegistrationLinkIncluded() {
      const doesLinkExist = Boolean(this.registrationUrl)
      return doesLinkExist
    },
    hasRegistrationDatePassed() {
      const now = moment()
      const hasDatePassed = now.isAfter(this.registrationDate)
      return hasDatePassed
    },
    registrationHint() {
      const hint = 'Registration not yet open'
      return hint
    },
    actionText() {
      return this.isClassFull ? 'Join waitlist' : 'Register online'
    },
    inactionText() {
      return this.hasRegistrationDatePassed ? 'Registration is currently closed' : this.registrationHint
    },
    buttonText() {
      const text = this.isRegistrationLinkIncluded ? this.actionText : this.inactionText
      return text
    },
    formattedStartTime() {
      return this.startTime.format('h:mma')
    },
    dayAndTime() {
      return `${this.day}s @ ${this.formattedStartTime}`
    },
    formattedStartDate() {
      return this.startDate.format('ddd MMM D')
    },
    formattedEndDate() {
      return this.endDate.format('ddd MMM D')
    },
    formattedFee() {
      const dollars = this.fee
      const fee = `$${dollars}`
      return fee
    },
  },
}
</script>

<style scoped>
.full-length {
  overflow: unset !important;
  text-overflow: unset !important;
  white-space: unset !important;
}

.theme--dark.v-btn.v-btn--disabled {
  color: var(--v-error-base) !important;
}
</style>

<template>
  <div id="available-camps">
    <CampList v-for="(week, i) in weeks" :key="week.cat3" :cat3="week.cat3" :camps="week.camps" :class="{ 'mt-12': i > 0 }" :cat3AsTitle="cat3AsTitle" />
  </div>
</template>

<script>
import * as adapters from '@/adapters'

import CampList from './CampList'

export default {
  components: {
    CampList,
  },
  props: {
    sessionId: String,
    cat3s: Array,
    cat3AsTitle: Boolean,
  },
  data() {
    return {
      weeks: [],
    }
  },
  watch: {
    cat3s() {
      this.setData()
    },
  },
  async mounted() {
    this.setData()
  },
  methods: {
    async setData() {
      // Fetch weeks from API
      const url = '/api/camps/'
      const payload = { session: this.sessionId, cat3s: this.cat3s }
      const response = await this.$http.post(url, payload)
      // Map weeks into frontend representation
      const weeks = adapters.weeks.fromApi(response.data)
      this.weeks = weeks
    },
  },
}
</script>

<style scoped>
#available-camps {
  background-image: url('../../assets/orange-background.jpg');
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>

<template>
  <v-btn v-if="isRegistrationOpen" small color="secondary" :outlined="isClassFull" rounded :href="registrationUrl" data-testid="camp-registration_proceed-button">
    {{ buttonText }}
  </v-btn>
  <span v-else data-testid="camp-registration_registration-hint">
    {{ registrationHint }}
  </span>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    spotsLeft: Number,
    registrationDate: Object,
    registrationUrl: String,
  },
  computed: {
    isRegistrationOpen() {
      const isOnlineRegistrationAllowed = Boolean(this.registrationUrl)
      const now = moment()
      const hasRegistrationDatePassed = now.isAfter(this.registrationDate)
      return isOnlineRegistrationAllowed && hasRegistrationDatePassed
    },
    registrationHint() {
      const formattedDate = this.registrationDate.format('ddd MMM D')
      const hint = `${formattedDate} @ 9pm`
      return hint
    },
    isClassFull() {
      const isClassFull = this.spotsLeft === 0
      return isClassFull
    },
    buttonText() {
      const text = this.isClassFull ? 'Join waitlist' : 'Register online'
      return text
    },
  },
}
</script>

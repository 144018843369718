import '@babel/polyfill'

import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import App from './App'
import vuetify from './plugins/vuetify'
import router from './router'

import VueGtag from 'vue-gtag'

// Initialize Google Analytics
const config = { id: process.env.VUE_APP_GA4_MEASUREMENT_ID }
const options = { config }
const isTest = localStorage.getItem('playwright.is-test') === 'true'
if (!isTest) Vue.use(VueGtag, options, router) // TODO Unpredictably reports one of document.title, route.name, or route.fullPath

// Integrate Axios into Vue
Vue.use(VueAxios, axios)

// Initialize and mount Vue instance
new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
